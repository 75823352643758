import { ReactComponent as Logo } from "assets/images/logo-main.svg";
import { ReactComponent as LogoMejadokter } from "assets/images/logo-mejadokter.svg";

import Button from "react-components/dist/components/Button";
import Form from "react-components/dist/components/Form";
import { useAppDispatch } from "lib/store/hooks";

import { login } from "lib/store/slices/users";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { setAuthorizationHeader } from "lib/fetch/axios";

import clinics from "lib/constants/api/clinics";
import auth from "lib/constants/api/auth";
import users from "lib/constants/api/users";
import {
  forceHideSidebar,
  resetAllForms,
  toggleSidebar,
} from "lib/store/slices/components";
import { selectClinic } from "lib/store/slices/clinics";
import Spinner from "react-components/dist/components/Spinner";
import { toast } from "react-toastify";
import useLocalStorage from "lib/helpers/useLocalstorage";
import Input from "react-components/dist/components/Input";
import { Eye, EyeClosed } from "@phosphor-icons/react";

interface ILoginProps {}

interface CustomizedState {
  from: {
    pathname: string;
  };
}

const entity: { [key: string]: string } = {
  owner: "owners",
  staff: "staffs",
  doctor: "doctors",
  nurse: "nurses",
};

export default function Login(props: ILoginProps) {
  const [welcomeUser, setWelcomeUser] = useState({
    name: "",
    email: "",
  });

  const [seePassword, seePasswordSet] = useState(false);

  const [inlineNotifications, inlineNotificationsSet] = useLocalStorage<
    string[]
  >("inlineNotifications", []);

  const [isFormLoading, setLoading] = useState(false);

  const [isLoadingRoot, isLoadingRootSet] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();

  const params = new URL(window.location.href).searchParams;
  let qq = window.location.href.split("&path=")[1];
  let queryParamsRedirect = "";
  if (qq) queryParamsRedirect = qq.split("?")[1];

  function fnChangeUser() {
    setWelcomeUser({ name: "", email: "" });
  }

  async function fnLogin(payload: any) {
    if (payload.email === "") {
      alert("email tidak boleh kosong");
      return;
    }

    if (payload.password === "") {
      alert("password tidak boleh kosong");
      return;
    }

    try {
      setLoading(true);
      const responseAuth = await auth.login(payload);
      const { user } = responseAuth.data;
      setAuthorizationHeader(user.token);

      const responseProfile = await users.getDetailsByEntity(
        entity[user.entity]
      );

      const profile = responseProfile?.data ?? {};

      dispatch(
        login({
          token: user.token,
          tokenRefresh: user.tokenRefresh,
          profile: {
            authority: user.authority,
            email: user.email,
            entity: user.entity,
            idUser: user._id,
            idProfile: profile._id,
            gender: profile.gender,
            dateOfBirth: profile.dateOfBirth,
            fullAddress: profile.fullAddress,
            fullName: profile.fullName,
            idCard: profile.idCard,
            ijinPraktek: profile.ijinPraktek,
            phoneNumber: profile.phoneNumber,
            statusPernikahan: profile.statusPernikahan,
            createdAt: profile.createdAt,
            updatedAt: profile.updatedAt,
            metaData: profile.metaData,
            isBidan: profile.isBidan,
            isApoteker: profile.isApoteker,
            isApotekerAuthorized: profile.isApotekerAuthorized,
          },
        })
      );
      const from = state?.from?.pathname || "/clinics";

      if (!inlineNotifications.includes("reset-all-forms")) {
        dispatch(resetAllForms());
        inlineNotificationsSet((prev) => [...prev, "reset-all-forms"]);
      }

      navigate(from, { replace: true });
    } catch (error: any) {
      setLoading(false);
      toast.warning(
        error?.isAxiosError
          ? error?.response?.data?.errors.global
          : error?.message
      );
    }
  }

  useLayoutEffect(() => {
    if (welcomeUser.email === "") document.getElementById("email")?.focus();
    else document.getElementById("password")?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginRoot = useCallback(
    async (
      token: string | null,
      clinic?: string | null,
      path?: string | null
    ) => {
      if (!isLoadingRoot) {
        try {
          if (!token) throw new Error("Token tidak valid");

          isLoadingRootSet(true);
          const authenticated = await auth.rootLogin({ token });
          const { user } = authenticated.data;

          setAuthorizationHeader(user.token);

          const responseProfile = await users.getDetailsByEntity(
            entity[user.entity]
          );

          const profile = responseProfile?.data ?? {};
          dispatch(
            login({
              token: user.token,
              tokenRefresh: user.tokenRefresh,
              isAdminMejadokter: user.isAdminMejadokter,
              profile: {
                authority: user.authority,
                email: user.email,
                entity: user.entity,
                idUser: user._id,
                idProfile: profile._id,
                dateOfBirth: profile.dateOfBirth,
                fullAddress: profile.fullAddress,
                fullName: profile.fullName,
                idCard: profile.idCard,
                phoneNumber: profile.phoneNumber,
                statusPernikahan: profile.statusPernikahan,
                createdAt: profile.createdAt,
                updatedAt: profile.updatedAt,
                isBidan: profile.isBidan,
                isApoteker: profile.isApoteker,
                isApotekerAuthorized: profile.isApotekerAuthorized,
              },
            })
          );

          if (typeof clinic === "string") {
            const getClinic = await clinics.select(clinic);

            dispatch(toggleSidebar(false));
            dispatch(
              selectClinic({
                ...getClinic.data.clinicDetails,
                poli: getClinic.data.poliHospital,
                doctor: getClinic.data.doctor,
                hospital: getClinic.data.hospital,
                iks: getClinic.data.iks,
                nurse: getClinic.data.nurse,
                diagnosa: getClinic.data.diagnosa,
              })
            );
            dispatch(
              login({
                isAdminMejadokter: user.isAdminMejadokter,
                token: getClinic.data.token,
                tokenRefresh: getClinic.data.tokenRefresh,
              })
            );

            dispatch(forceHideSidebar(false));

            if (path?.includes("?")) path = path.split("?")[0];
            if (path?.includes(":clinic"))
              path = path.replace(":clinic", clinic);
            if (path?.includes(":patient"))
              path = path.replace(":patient", params.get("patient") || "");
            if (path?.includes(":service"))
              path = path.replace(":service", params.get("service") || "");

            if (queryParamsRedirect !== "") path += `?${queryParamsRedirect}`;
          }

          navigate(path || "/clinics");
        } catch (error) {
          console.log(error);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, navigate, params, isLoadingRoot]
  );

  useEffect(() => {
    if (params.has("token") && params.get("root") === "1") {
      loginRoot(params.get("token"), params.get("clinic"), params.get("path"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.has("reason") && params.get("reason") === "session-expired") {
      toast.warning("Sesi Anda telah berakhir, silahkan login kembali", {
        toastId: "sessionExpired",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="h-screen bg-gray-50 dark:bg-gray-950">
      <div className="flex h-screen flex-col items-center justify-start">
        <div className="mt-16">
          <Logo width={220} className="logo-yellow" />
        </div>
        <div className="mt-8">
          <div className="relative z-10 mx-4 flex w-auto flex-col rounded-sm bg-white dark:bg-gray-900 p-8 shadow-xl dark:shadow-gray-950 md:w-96">
            {params.has("token") &&
            params.get("root") === "1" &&
            isLoadingRoot ? (
              <div className="flex h-40 items-center justify-center dark:text-gray-200">
                <Spinner size="sm" />
                <span className="mb-4 ml-3 md:mb-0">
                  Mempersiapkan akun faskes Anda
                </span>
              </div>
            ) : (
              <>
                <h3
                  className="text-center text-sm dark:text-gray-400"
                  data-uid-testing="login-message"
                >
                  Selamat datang{" "}
                  {(welcomeUser.name && welcomeUser.name.split(" ")[0]) ||
                    "mitra mejakasir"}
                  {welcomeUser.email !== "" && (
                    <span>
                      ,{" "}
                      <span
                        className="cursor-pointer text-blue-600 underline"
                        onClick={fnChangeUser}
                      >
                        ganti user
                      </span>
                    </span>
                  )}
                </h3>
                <div className="mt-6">
                  <Form onSubmit={fnLogin}>
                    <div className="flex flex-col gap-y-4">
                      <Input
                        isRequired
                        id="email"
                        name="email"
                        type="email"
                        label="Username/email"
                        defaultValue=""
                        className="px-4"
                      />

                      <Input
                        isRequired
                        id="password"
                        name="password"
                        type={seePassword ? "text" : "password"}
                        label="Password"
                        defaultValue=""
                        className="px-4"
                        wrapperClassName="relative"
                        addOn={
                          <span className="absolute top-0 right-0 h-full aspect-square">
                            <button
                              type="button"
                              className="w-full h-full flex items-center justify-center dark:text-gray-200"
                              onClick={() => seePasswordSet((prev) => !prev)}
                            >
                              {seePassword ? (
                                <Eye size={18} />
                              ) : (
                                <EyeClosed size={18} />
                              )}
                            </button>
                          </span>
                        }
                      />

                      <Button
                        isLoading={isFormLoading}
                        ripple
                        className="w-full justify-center rounded"
                      >
                        <button data-uid-testing="submit">Masuk</button>
                      </Button>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </div>
        </div>
        {/* 
        <Pill size="sm" state="info" className="mt-4">
          Mejadokter V1 akan tutup layanan pada 1 Februari 2024, dan akan
          dilanjutkan pada Mejadokter V2
        </Pill> */}

        <div className="flex flex-col items-center gap-y-4 justify-center my-8 text-center">
          <h6 className="text-sm text-gray-900 dark:text-gray-200">
            Aplikasi lainnya
          </h6>
          <div className="flex gap-x-4 h-10">
            <span className="flex items-center gap-x-2 relative">
              <LogoMejadokter className="logo-white h-10 w-10 rounded" />
              <span className="font-bold text-xl text-gray-900 dark:text-gray-200">
                mejadokter
              </span>
              <a
                href="https://mejadokter.com?ref=mejakasir"
                target="_blank"
                rel="noopener noreferrer"
                className="absolute inset-0"
              >
                {/* <!-- mejadokter link --> */}
              </a>
            </span>
          </div>
        </div>

        <div className="relative flex-col gap-y-4 items-center lg:flex-row z-10 mt-8 flex text-sm text-gray-600 dark:text-gray-400">
          <a
            href="https://mejadokter.com/terms-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Syarat & Ketentuan
          </a>

          <span className="px-4 hidden lg:inline-block">|</span>

          <a
            href="https://mejadokter.com/privacy-policies"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kebijakan Privasi
          </a>
        </div>

        {/* <div className="relative md:absolute mt-8 bottom-0 left-0 right-0 flex justify-center">
          <img src="/assets/images/login-bg.png" alt="Login Mejadokter" />
        </div> */}
      </div>
    </main>
  );
}
