import { Gear } from "@phosphor-icons/react";

// import { useAppSelector } from "lib/store/hooks";
import { Link } from "react-router-dom";

// type Props = {}

function ContextMenuLockMainMenu() {
  // const users = useAppSelector((state) => state.users);

  return (
    <div className="absolute bottom-0 left-0 p-1 bg-white rounded shadow-lg">
      <div className="p-2 flex flex-col gap-y-1">
        <h3 className="text-sm">PT DLN Karya Bangsa</h3>
        <p className="text-xs">
          Dengan merek dagang Mejakasir adalah sebuah aplikasi kasir yang bisa
          digunakan untuk berbagai jenis toko yang menjual produk dan atau jasa.
        </p>
        <p className="text-xs">
          Mejakasir adalah saudara dari Mejadokter yang merupakan aplikasi
          penunjang fasilitas kesehatan dalam mencatat elektronik rekam medis
          dan manajemen faskes.
        </p>
        <p className="text-xs">
          Sudah terdaftar di PSE (
          <a
            href="https://pse.kominfo.go.id/tdpse-detail/11486"
            rel="noopener noreferrer"
            target="_blank"
            className="underline"
          >
            Tautan PSE
          </a>
          )
        </p>
      </div>
      <ul className="flex flex-col gap-y-1">
        <li className="">
          <Link
            to="/settings"
            className="flex gap-x-3 items-center px-3 py-1 bg-slate-50 hover:bg-slate-100"
          >
            <Gear size={22} className="flex-none" /> Pengaturan
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default ContextMenuLockMainMenu;
