import ProtectedAccountRoute from "components/atoms/ProtectedAccountRoute";
import Header from "components/organisms/Header";
import Sidebar from "components/organisms/Sidebar";

import { Suspense, useEffect, useState } from "react";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/molecules/ErrorFallback";
import { createResource } from "lib/helpers/createResource";

import clinics from "lib/constants/api/clinics";

import ListClinics from "./ListClinics";

interface IOwnerProps {}

function FallbackComponent() {
  return (
    <div className="flex flex-col gap-y-5 mt-16 px-4">
      <div className="flex flex-col">
        <h2
          className="text-sm text-gray-600 dark:text-gray-400"
          data-uid-testing="page-faskes-selection"
        >
          Toko Anda
        </h2>
        <span className="text-gray-800 dark:text-gray-200">
          Klik tombol <strong>Kelola Toko</strong> untuk memulai
        </span>
      </div>
      <div className="flex flex-col lg:flex-row gap-x-4">
        {Array(3)
          .fill("fallback")
          .map((item, id) => {
            return (
              <div
                className="relative flex w-full gap-x-6 rounded-lg p-6 bg-gray-50 dark:bg-gray-800 shadow-lg"
                key={`${item}-${id}`}
              >
                <div className="w-10 flex-none">
                  <span
                    className={[
                      "inline-flex h-10 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                    ].join(" ")}
                  ></span>
                </div>
                <div className="flex w-full flex-wrap items-center gap-4">
                  <span
                    className={[
                      "inline-flex h-4 w-36 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                    ].join(" ")}
                  ></span>
                  <span
                    className={[
                      "inline-flex h-4 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                    ].join(" ")}
                  ></span>
                  <span
                    className={[
                      "inline-flex h-4 w-56 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                    ].join(" ")}
                  ></span>
                  <span
                    className={[
                      "inline-flex h-4 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                    ].join(" ")}
                  ></span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default function Owner(props: IOwnerProps) {
  const [resource, setResource] = useState(null);

  useEffect(() => {
    setResource(createResource(clinics.list()));
  }, []);

  return (
    <ProtectedAccountRoute>
      <>
        <Header />
        <div className="flex">
          <Sidebar />
          <main className="flex w-screen flex-none items-start justify-center md:flex-auto">
            <div className="w-full max-w-5xl">
              <ErrorBoundary
                fallbackRender={(props) => <ErrorFallback {...props} />}
              >
                <Suspense fallback={<FallbackComponent />}>
                  <ListClinics resource={resource} />
                </Suspense>
              </ErrorBoundary>
            </div>
          </main>
        </div>
      </>
    </ProtectedAccountRoute>
  );
}
