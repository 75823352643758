function addScripts(url: string, options: { async?: boolean } = {}) {
  const src = document.createElement("script");

  src.setAttribute("src", url);

  if (options.async) src.setAttribute("async", String(options.async));

  src.setAttribute("type", "text/javascript");

  document.body.appendChild(src);
}

export default addScripts;
