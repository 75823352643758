import { useAppDispatch, useAppSelector } from "lib/store/hooks";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import services from "lib/constants/api/services";
import APICalendar from "lib/constants/api/calendar";

import { updateServiceState } from "lib/store/slices/services";
import { updateCalendarState } from "lib/store/slices/calendars";
// import { updateClinicMetaData } from "lib/store/slices/clinics";

// import isBefore from "date-fns/isBefore";
// import addDays from "date-fns/addDays";

function useControlClinic() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const clinics = useAppSelector((state) => state.clinics);

  const updateClinicServicesStates = useCallback(async () => {
    if (clinics.selected) {
      const responseService = await services.getStates();
      dispatch(updateServiceState(responseService.data));

      const responseBooking = await APICalendar.getStates();
      dispatch(updateCalendarState(responseBooking.data));
    }
  }, [dispatch, clinics]);

  useEffect(() => {
    updateClinicServicesStates();
  }, [updateClinicServicesStates, location]);

  return null;
}

export default useControlClinic;
