import { Question } from "@phosphor-icons/react";
import { useAppSelector } from "lib/store/hooks";

import Button from "react-components/dist/components/Button";
import modal from "react-components/dist/components/Modal";
import { Link } from "react-router-dom";

function ModalYoutubeEmbed({ uri }: { uri: string }) {
  return (
    <div className="aspect-video w-full border-4 border-white">
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${uri}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
}

function HelpButton() {
  const tutorials = useAppSelector((state) => state.tutorials);

  function modalYoutubeEmbed(uri: string) {
    modal.show(<ModalYoutubeEmbed uri={uri} />, {
      wrapper: {
        style: {
          width: 1200,
        },
      },
    });
  }

  return (
    <div className="group relative">
      <Button
        ripple
        className="w-10 justify-center rounded-full bg-blue-800 dark:bg-gray-900 "
        state="custom"
      >
        {!!tutorials.youtube && tutorials.youtube?.embedURI !== "" ? (
          <button
            type="button"
            onClick={() =>
              modalYoutubeEmbed(tutorials.youtube?.embedURI as string)
            }
          >
            <Question className="text-yellow-700" weight="duotone" size={28} />
          </button>
        ) : (
          <Link to="/blogs">
            <Question className="text-white" size={22} />
          </Link>
        )}
      </Button>
      <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
        {tutorials.youtube?.title || "Buku Manual / Bantuan"}
      </span>
    </div>
  );
}

export default HelpButton;
