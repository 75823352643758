import { useLayoutEffect, lazy, Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { toggleApp } from "lib/store/slices/components";
import { useAppDispatch, useAppSelector } from "lib/store/hooks";
import { setAuthorizationHeader } from "lib/fetch/axios";
import useControlClinic from "lib/helpers/useControlClinic";
import useSpeech from "lib/helpers/useSpeech";

// PAGES

import Owner from "pages/Owner";
import Login from "pages/Login";
import NotFound from "pages/Errors/NotFound";
import AuthorizedOnly from "pages/Errors/AuthorizedOnly";

import ClinicsOutlet from "components/organisms/ClinicsOutlet";
import LoadingOutlet from "components/molecules/FallbackComponent/LoadingOutlet";

import { useUnleashContext } from "@unleash/proxy-client-react";

import * as Sentry from "@sentry/browser";
import addScripts from "lib/helpers/addScripts";

// pelayanan -> laboratorium
// const LaboratoriumAntrian = lazy(() => import("pages/Pelayanan/Laboratorium"));
// const LaboratoriumPemeriksaan = lazy(
//   () => import("pages/Pelayanan/Laboratorium/Pemeriksaan")
// );
// const LaboratoriumAdministrasi = lazy(
//   () => import("pages/Pelayanan/Laboratorium/Administrasi")
// );

// pelayanan -> rawat jalan
// const RawatJalanAntrian = lazy(() => import("pages/Pelayanan/RawatJalan"));
// const RawatJalanPemeriksaan = lazy(
//   () => import("pages/Pelayanan/RawatJalan/Pemeriksaan")
// );
// const RawatJalanAdministrasi = lazy(
//   () => import("pages/Pelayanan/RawatJalan/Administrasi")
// );

// pelayanan -> oncall
// const OncallAntrian = lazy(() => import("pages/Pelayanan/Oncall"));
// const OncallPemeriksaan = lazy(
//   () => import("pages/Pelayanan/Oncall/Pemeriksaan")
// );
// const OncallAdministrasi = lazy(
//   () => import("pages/Pelayanan/Oncall/Administrasi")
// );

// pelayanan -> Instalasi Farmasi
// const ServicesInstalasiFarmasi = lazy(
//   () => import("pages/Pelayanan/InstalasiFarmasi")
// );
// const ServicesInstalasiFarmasiForm = lazy(
//   () => import("pages/Pelayanan/InstalasiFarmasi/Form")
// );

// pelayanan -> Rawat Inap
const AntrianRawatInap = lazy(() => import("pages/Pelayanan/RawatInap"));
const RawatInapPemeriksaan = lazy(
  () => import("pages/Pelayanan/RawatInap/Pemeriksaan")
);
const RawatInapAdministrasi = lazy(
  () => import("pages/Pelayanan/RawatInap/Administrasi")
);

// pelayanan -> UGD
// const AntrianUGD = lazy(() => import("pages/Pelayanan/UGD"));
// const UGDPemeriksaan = lazy(() => import("pages/Pelayanan/UGD/Pemeriksaan"));
// const UGDAdministrasi = lazy(() => import("pages/Pelayanan/UGD/Administrasi"));

// Pages Point of Sales
const PointOfSalesTransactions = lazy(
  () => import("pages/PointOfSales/Transactions")
);
const PointOfSalesWaiting = lazy(() => import("pages/PointOfSales/Waiting"));
const PointOfSalesLogs = lazy(() => import("pages/PointOfSales/Logs"));
const PointOfSalesLogDetails = lazy(
  () => import("pages/PointOfSales/LogDetails")
);

// Notifications
const Dashboard = lazy(() => import("pages/Dashboard"));
const Notifications = lazy(() => import("pages/Notifications"));
// const Help = lazy(() => import("pages/Help"));
const UbahProfil = lazy(() => import("pages/UbahProfil"));
const UbahPassword = lazy(() => import("pages/UbahPassword"));
const Balance = lazy(() => import("pages/Balance"));
const Calendars = lazy(() => import("pages/Calendars"));

// pages Manajemen Data
// import ManajemenData from "pages/ManajemenData/Overview";
const ManajemenData = lazy(() => import("pages/ManajemenData/Overview"));
// import ManajemenDataStaff from "pages/ManajemenData/Staff";
const ManajemenDataStaff = lazy(() => import("pages/ManajemenData/Staff"));
// import ManajemenDataStaffNew from "pages/ManajemenData/Staff/New";
const ManajemenDataStaffNew = lazy(
  () => import("pages/ManajemenData/Staff/New")
);
// import ManajemenDataStaffDetails from "pages/ManajemenData/Staff/Details";
const ManajemenDataStaffDetails = lazy(
  () => import("pages/ManajemenData/Staff/Details")
);
// import ManajemenDataPasien from "pages/ManajemenData/Pasien";
const ManajemenDataPasien = lazy(() => import("pages/ManajemenData/Pasien"));
// import ManajemenDataPasienDetails from "pages/ManajemenData/Pasien/Details";
const ManajemenDataPasienDetails = lazy(
  () => import("pages/ManajemenData/Pasien/Details")
);
// import ManajemenDataPasienGeneralConsent from "pages/ManajemenData/Pasien/Details/GeneralConsent";
const ManajemenDataPasienGeneralConsent = lazy(
  () => import("pages/ManajemenData/Pasien/Details/GeneralConsent")
);
const ManajemenDataPasienPackageBalance = lazy(
  () => import("pages/ManajemenData/Pasien/Details/Packagebalance")
);

// import ManajemenDataPasienModalGeneralConsent from "pages/ManajemenData/Pasien/Details/ModalGeneralConsent";
const ManajemenDataPasienModalGeneralConsent = lazy(
  () => import("pages/ManajemenData/Pasien/Details/ModalGeneralConsent")
);
const ManajemenDataPasienModalMedicalRecords = lazy(
  () => import("pages/ManajemenData/Pasien/Details/ModalMedicalRecords")
);
const ManajemenDataPoli = lazy(() => import("pages/ManajemenData/Polies"));
const ManajemenDataPoliDetails = lazy(
  () => import("pages/ManajemenData/Polies/Details")
);
const ManajemenDataIks = lazy(() => import("pages/ManajemenData/IKS"));
const ManajemenDataIksDetails = lazy(
  () => import("pages/ManajemenData/IKS/Details")
);
const ManajemenDataRuangInap = lazy(
  () => import("pages/ManajemenData/RuangInap")
);
const ManajemenDataProducts = lazy(
  () => import("pages/ManajemenData/Products")
);
const ManajemenDataProductDetails = lazy(
  () => import("pages/ManajemenData/Products/Details")
);

const ManajemenDataSupplier = lazy(
  () => import("pages/ManajemenData/Suppliers")
);
const ManajemenDataSupplierPurchaseOrder = lazy(
  () => import("pages/ManajemenData/Suppliers/PurchaseOrder")
);
const ManajemenDataSupplierCreateUpdate = lazy(
  () => import("pages/ManajemenData/Suppliers/CreateUpdateForm")
);
const ManajemenDataSupplierView = lazy(
  () => import("pages/ManajemenData/Suppliers/ListPiutang")
);

const ManajemenDataDocumentTemplates = lazy(
  () => import("pages/ManajemenData/Documents")
);
const ManajemenDataDocumentTemplatesCreateUpdate = lazy(
  () => import("pages/ManajemenData/Documents/CreateUpdate")
);

// import Histories from "pages/Histories";
const Histories = lazy(() => import("pages/Histories"));

const IntegrationsBPJSSettings = lazy(
  () => import("pages/Integration/BPJS/Settings")
);
const IntegrationsBPJSEncounter = lazy(
  () => import("pages/Integration/BPJS/ListKunjungan")
);

const IntegrationsLandingPage = lazy(
  () => import("pages/Integration/LandingPage")
);

const IntegrationsSatuSehat = lazy(() => import("pages/Integration/SatuSehat"));
const IntegrationsSatuSehatWelcome = lazy(
  () => import("pages/Integration/SatuSehat/Welcome")
);

const Crm = lazy(() => import("pages/Integration/Crm"));
const MarketingCampaign = lazy(
  () => import("pages/Integration/Crm/MarketingCampaign")
);
const CrmRiwayat = lazy(() => import("pages/Integration/Crm/Logs"));
const CommunicationTemplateLists = lazy(
  () => import("pages/Integration/Crm/Communication/TemplateLists")
);
const CommunicationTemplateCreateUpdate = lazy(
  () => import("pages/Integration/Crm/Communication/TemplateCreateUpdate")
);
const CrmSurvey = lazy(() => import("pages/Integration/Crm/Survey"));
const CrmFeedback = lazy(() => import("pages/Integration/Crm/Feedback"));
const CrmChatbot = lazy(() => import("pages/Integration/Crm/Chatbot"));
const CrmLoyalty = lazy(() => import("pages/Integration/Crm/Loyalty"));
const CrmOmnichannel = lazy(() => import("pages/Integration/Crm/Omnichannel"));
const CrmSegmentation = lazy(
  () => import("pages/Integration/Crm/Segmentation")
);

// reports pelayanan
// import ReportsPelayananRawatJalan from "pages/Reports/Pelayanan/RawatJalan";
const ReportsPelayananRawatJalan = lazy(
  () => import("pages/Reports/Pelayanan/RawatJalan")
);
const ReportsPelayananRawatJalanKunjungan = lazy(
  () => import("pages/Reports/Pelayanan/RawatJalan/Kunjungan")
);
const ReportsPelayananUGD = lazy(() => import("pages/Reports/Pelayanan/UGD"));
const ReportsPelayananUGDKunjungan = lazy(
  () => import("pages/Reports/Pelayanan/UGD/Kunjungan")
);
const ReportsPelayananOncall = lazy(
  () => import("pages/Reports/Pelayanan/Oncall")
);
const ReportsPelayananOncallKunjungan = lazy(
  () => import("pages/Reports/Pelayanan/Oncall/Kunjungan")
);

// reports sales
// import ReportsPenjualanRawatJalan from "pages/Reports/Penjualan/RawatJalan";
const ReportsPenjualanRawatJalanOverview = lazy(
  () => import("pages/Reports/Penjualan/RawatJalanOverview")
);
const ReportsPenjualanRawatJalanDetails = lazy(
  () => import("pages/Reports/Penjualan/RawatJalanDetails")
);

const ReportsPenjualanRawatInapOverview = lazy(
  () => import("pages/Reports/Penjualan/RawatInapOverview")
);
const ReportsPenjualanRawatInapDetails = lazy(
  () => import("pages/Reports/Penjualan/RawatInapDetails")
);

const ReportsPenjualanUGDOverview = lazy(
  () => import("pages/Reports/Penjualan/UGDOverview")
);
const ReportsPenjualanUGDDetails = lazy(
  () => import("pages/Reports/Penjualan/UGDDetails")
);

const ReportsPenjualanOncallOverview = lazy(
  () => import("pages/Reports/Penjualan/OncallOverview")
);
const ReportsPenjualanOncallDetails = lazy(
  () => import("pages/Reports/Penjualan/OncallDetails")
);

const ReportsPenjualanLangsungOverview = lazy(
  () => import("pages/Reports/Penjualan/PenjualanLangsungOverview")
);
const ReportsPenjualanLangsungDetails = lazy(
  () => import("pages/Reports/Penjualan/PenjualanLangsungDetails")
);
// import ReportsPenerbitanSurat from "pages/Reports/PenerbitanSurat";
const ReportsPenerbitanSurat = lazy(
  () => import("pages/Reports/PenerbitanSurat")
);
// import ReportsBagiHasilTindakan from "pages/Reports/BagiHasilTindakan";
const ReportsBagiHasilTindakan = lazy(
  () => import("pages/Reports/BagiHasilTindakan")
);
// import ReportsApotek from "pages/Reports/Apotek";
const ReportsApotek = lazy(() => import("pages/Reports/Apotek"));
const LogsApotek = lazy(() => import("pages/Reports/Apotek/Logs"));
const PuyerApotek = lazy(() => import("pages/Reports/Apotek/Puyer"));

// import ReportsApotekDrugsCondition from "pages/Reports/Apotek/KondisiStok";
const ReportsApotekDrugsCondition = lazy(
  () => import("pages/Reports/Apotek/KondisiStok")
);

// import SettingsGeneral from "pages/Settings/General";
const SettingsGeneral = lazy(() => import("pages/Settings/General"));
// import SettingsLogo from "pages/Settings/Logo";
const SettingsLogo = lazy(() => import("pages/Settings/Logo"));
// import SettingsInstalasiFarmasi from "pages/Settings/InstalasiFarmasi";
const SettingsInstalasiFarmasi = lazy(
  () => import("pages/Settings/InstalasiFarmasi")
);
// import SettingsCodingRM from "pages/Settings/CodingRM";
const SettingsCodingRM = lazy(() => import("pages/Settings/CodingRM"));
// import SettingsPointOfSales from "pages/Settings/PointOfSales";
const SettingsPointOfSales = lazy(() => import("pages/Settings/PointOfSales"));
// import SettingsBPJS from "pages/Settings/BPJS";
const SettingsBPJS = lazy(() => import("pages/Settings/BPJS"));
// import SettingsRawatInap from "pages/Settings/RawatInap";
const SettingsRawatInap = lazy(() => import("pages/Settings/RawatInap"));
// import SettingsHakKewajibanPasien from "pages/Settings/HakKewajibanPasien";
const SettingsHakKewajibanPasien = lazy(
  () => import("pages/Settings/HakKewajibanPasien")
);
const SettingsUGD = lazy(() => import("pages/Settings/UGD"));
const SettingsOnCall = lazy(() => import("pages/Settings/OnCall"));

// import SettingsCalendars from "pages/Settings/Calendars";
const SettingsCalendars = lazy(() => import("pages/Settings/Calendars"));
// import SettingsKiosk from "pages/Settings/Kiosk";
const SettingsKiosk = lazy(() => import("pages/Settings/Kiosk"));
const SettingsAnnouncers = lazy(() => import("pages/Settings/Announcers"));
// import SettingsNotifications from "pages/Settings/SettingsNotifications";

const Blogs = lazy(() => import("pages/Blogs"));
const BlogsSlug = lazy(() => import("pages/Blogs/Slug"));

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function App() {
  useControlClinic();
  useSpeech();

  const updateContext = useUnleashContext();

  const location = useLocation();

  const locationState = location.state as { backgroundLocation?: Location };

  const clinics = useAppSelector((state) => state.clinics);
  const users = useAppSelector((state) => state.users);
  const components = useAppSelector((state) => state.components);

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (process.env.REACT_APP_STAGING === "PRODUCTION") {
      (function (w: any, d: any, s: any, l: any, i: any) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(
        window,
        document,
        "script",
        "dataLayer",
        process.env.REACT_APP_MAIN_APP_GTAG_MANAGER
      );

      addScripts(
        `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_MAIN_APP_GTAG_ANALYTICS}`,
        { async: true }
      );

      addScripts(
        `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_MAIN_APP_GTAG_UA}`,
        { async: true }
      );

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        js: new Date(),
      });
      window.dataLayer.push({
        config: process.env.REACT_APP_MAIN_APP_GTAG_ANALYTICS,
      });
      window.dataLayer.push({
        config: process.env.REACT_APP_MAIN_APP_GTAG_UA,
      });

      if (clinics.selected) {
        window.dataLayer.push({
          clinicName: clinics.selected.name,
        });
      }
      if (users.profile) {
        window.dataLayer.push({
          usersName: users.profile.fullName,
        });
      }
    }
  }, [clinics.selected, users]);

  useLayoutEffect(() => {
    if (users.token) {
      setAuthorizationHeader(users.token);
    }
    setTimeout(() => {
      dispatch(toggleApp(true));
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, users]);

  useLayoutEffect(() => {
    document.body!.classList.remove("dark");
    document.body!.classList.remove("light");

    if (components.app.colorScheme === "system-default") {
      const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

      document.body!.classList.add(isDark ? "dark" : "light");
    } else {
      document.body!.classList.add(components.app.colorScheme);
    }
  }, [components.app.colorScheme]);

  useEffect(() => {
    if (clinics.selected) {
      updateContext({ userId: clinics.selected?._id });
    }
    // if (users?.profile?.idUser) {
    //   updateContext({ sessionId: users?.profile?.idUser });
    // }
  }, [clinics, updateContext]);

  useEffect(() => {
    if (users && clinics) {
      Sentry.setContext("User", {
        name: users.profile?.fullName || "Belum input nama",
        email: users.profile?.email,
        entity: users.profile?.entity,
      });
      Sentry.setContext("Clinic", {
        name: clinics.selected?.name,
        email: clinics.selected?.email,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinics, users]);

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      {!!users.isAdminMejadokter && (
        <span className="fixed top-0 right-0 z-50 pointer-events-none cursor-default">
          <span className="text-xxs uppercase px-2 py-1 font-bold bg-green-300 text-gray-900">
            Act as admin
          </span>
        </span>
      )}
      <Routes location={locationState?.backgroundLocation || location}>
        <Route index element={<Owner />} />
        <Route
          path="ubah-profil"
          element={
            <Suspense fallback={<LoadingOutlet />}>
              <UbahProfil />
            </Suspense>
          }
        />
        <Route
          path="ubah-password"
          element={
            <Suspense fallback={<LoadingOutlet />}>
              <UbahPassword />
            </Suspense>
          }
        />

        <Route path="clinics">
          <Route index element={<Owner />} />

          <Route path=":id" element={<ClinicsOutlet />}>
            <Route
              index
              element={
                <Suspense fallback={<LoadingOutlet />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="dashboard"
              element={
                <Suspense fallback={<LoadingOutlet />}>
                  <Dashboard />
                </Suspense>
              }
            />

            <Route
              path="notifications"
              element={
                <Suspense fallback={<LoadingOutlet />}>
                  <Notifications />
                </Suspense>
              }
            />

            <Route
              path="balance"
              element={
                <Suspense fallback={<LoadingOutlet />}>
                  <Balance />
                </Suspense>
              }
            />

            {(users.profile?.entity === "owner" ||
              !!users.profile?.authority?.appointment) && (
              <Route path="calendars">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Calendars />
                    </Suspense>
                  }
                />
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.service?.["list-patient"],
                users.profile?.authority?.service?.["nursing-care"],
                !!users.profile?.authority?.service?.["medical-record"],
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.treatment,
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.history,
                users.profile?.authority?.service?.["laboratorium"],
                users.profile?.authority?.service?.["pharmacy-transactions"],
              ].some((item) => item)) && (
              <Route path="services">
                <Route path="rawat-inap">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <AntrianRawatInap />
                      </Suspense>
                    }
                  />

                  <Route
                    path=":id/:type"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <RawatInapPemeriksaan />
                      </Suspense>
                    }
                  />

                  <Route
                    path=":id/administrasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <RawatInapAdministrasi />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.pos?.create,
                users.profile?.authority?.pos?.logs,
              ].some((item) => item)) && (
              <Route path="pos">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <PointOfSalesTransactions />
                    </Suspense>
                  }
                />
                <Route
                  path="transactions"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <PointOfSalesTransactions />
                    </Suspense>
                  }
                />
                <Route
                  path="waiting"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <PointOfSalesWaiting />
                    </Suspense>
                  }
                />
                <Route path="logs">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <PointOfSalesLogs />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <PointOfSalesLogDetails />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.manage?.doctor,
                users.profile?.authority?.manage?.nurse,
                users.profile?.authority?.manage?.staff,
                users.profile?.authority?.manage?.patient,
                users.profile?.authority?.manage?.iks,
                users.profile?.authority?.manage?.["rumah-sakit"],
                users.profile?.authority?.manage?.["poli-rumah-sakit"],
                users.profile?.authority?.manage?.products,
                users.profile?.authority?.manage?.units,
                users.profile?.authority?.manage?.["product-categories"],
              ].some((item) => item)) && (
              <Route path="manages">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <ManajemenData />
                    </Suspense>
                  }
                />

                {(users.profile?.entity === "owner" ||
                  [
                    users.profile?.authority?.manage?.doctor,
                    users.profile?.authority?.manage?.nurse,
                    users.profile?.authority?.manage?.staff,
                  ].some((item) => item)) && (
                  <Route path="employees">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataStaff />
                        </Suspense>
                      }
                    />
                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataStaffNew />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":idUser"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataStaffDetails />
                        </Suspense>
                      }
                    />
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  [users.profile?.authority?.manage?.patient].some(
                    (item) => item
                  )) && (
                  <Route path="patients">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPasien />
                        </Suspense>
                      }
                    />
                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPasienDetails />
                        </Suspense>
                      }
                    />
                    <Route path=":idPatient">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataPasienDetails />
                          </Suspense>
                        }
                      />
                      <Route
                        path="package-balance"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataPasienPackageBalance />
                          </Suspense>
                        }
                      />
                      <Route
                        path="general-consent"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataPasienGeneralConsent />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                <Route
                  path="iks"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <ManajemenData />
                    </Suspense>
                  }
                />

                <Route
                  path="hospitals"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <ManajemenData />
                    </Suspense>
                  }
                />

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.polies) && (
                  <Route path="polies">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPoli />
                        </Suspense>
                      }
                    />
                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPoliDetails />
                        </Suspense>
                      }
                    />
                    <Route path=":idPoli">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataPoliDetails />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.iks) && (
                  <Route path="iks">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataIks />
                        </Suspense>
                      }
                    />
                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataIksDetails />
                        </Suspense>
                      }
                    />
                    <Route path=":idIks">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataIksDetails />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                <Route
                  path="inpatients"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <ManajemenDataRuangInap />
                    </Suspense>
                  }
                />

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.products) && (
                  <Route path="products">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataProducts />
                        </Suspense>
                      }
                    />

                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataProductDetails />
                        </Suspense>
                      }
                    />

                    <Route path=":idProduct">
                      <Route
                        path="edit"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataProductDetails />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.suppliers) && (
                  <Route path="suppliers">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataSupplier />
                        </Suspense>
                      }
                    />

                    <Route
                      path="purchase-order"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataSupplierPurchaseOrder />
                        </Suspense>
                      }
                    />

                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataSupplierCreateUpdate />
                        </Suspense>
                      }
                    />

                    <Route path=":idSupplier">
                      <Route
                        path="view"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataSupplierView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="edit"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataSupplierCreateUpdate />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.documents) && (
                  <Route path="documents">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataDocumentTemplates />
                        </Suspense>
                      }
                    />

                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataDocumentTemplatesCreateUpdate />
                        </Suspense>
                      }
                    />

                    <Route path=":idDocument">
                      {/* <Route
                        path="view"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataSupplierView />
                          </Suspense>
                        }
                      /> */}
                      <Route
                        path="edit"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataDocumentTemplatesCreateUpdate />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.reports?.sales,
                users.profile?.authority?.reports?.services,
                users.profile?.authority?.reports?.administrasi,
                users.profile?.authority?.reports?.["kunjungan-pasien"],
                users.profile?.authority?.reports?.["tindakan"],
                users.profile?.authority?.reports?.["apotek"],
              ].some((item) => item)) && (
              <Route path="reports">
                {(users.profile?.entity === "owner" ||
                  !!users.profile?.authority?.reports?.services) && (
                  <Route path="services">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsPelayananRawatJalan />
                        </Suspense>
                      }
                    />
                    <Route path="rawat-jalan">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananRawatJalan />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananRawatJalan />
                          </Suspense>
                        }
                      />
                      <Route
                        path="kunjungan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananRawatJalanKunjungan />
                          </Suspense>
                        }
                      />
                    </Route>

                    <Route path="ugd">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananUGD />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananUGD />
                          </Suspense>
                        }
                      />
                      <Route
                        path="kunjungan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananUGDKunjungan />
                          </Suspense>
                        }
                      />
                    </Route>

                    <Route path="oncall">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananOncall />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananOncall />
                          </Suspense>
                        }
                      />
                      <Route
                        path="kunjungan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananOncallKunjungan />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  !!users.profile?.authority?.reports?.sales) && (
                  <Route path="sales">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsPenjualanRawatJalanOverview />
                        </Suspense>
                      }
                    />
                    <Route path="rawat-jalan">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanOverview />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="tindakan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanTindakan />
                          </Suspense>
                        }
                      /> */}
                      <Route
                        path="details"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanDetails />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="puyer"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanPuyer />
                          </Suspense>
                        }
                      /> */}
                    </Route>

                    <Route path="rawat-inap">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapOverview />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="tindakan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapTindakan />
                          </Suspense>
                        }
                      /> */}
                      <Route
                        path="details"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapDetails />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="puyer"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapPuyer />
                          </Suspense>
                        }
                      /> */}
                    </Route>

                    <Route path="ugd">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanUGDOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanUGDOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="details"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanUGDDetails />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="puyer"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanUGDPuyer />
                          </Suspense>
                        }
                      /> */}
                    </Route>

                    <Route path="oncall">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanOncallOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanOncallOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="tindakan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanOncallDetails />
                          </Suspense>
                        }
                      />
                    </Route>

                    <Route path="pos">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanLangsungOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanLangsungOverview />
                          </Suspense>
                        }
                      />

                      <Route
                        path="details"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanLangsungDetails />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  !!users.profile?.authority?.reports?.administrasi) && (
                  <Route
                    path="administrasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <ReportsPenerbitanSurat />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.entity === "doctor" ||
                  !!users.profile?.authority?.reports?.["tindakan"]) && (
                  <Route
                    path="bagi-hasil-tindakan"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <ReportsBagiHasilTindakan />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  !!users.profile?.authority?.reports?.apotek) && (
                  <Route path="apotek">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsApotek />
                        </Suspense>
                      }
                    />
                    <Route
                      path="overview"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsApotek />
                        </Suspense>
                      }
                    />
                    <Route
                      path="logs"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <LogsApotek />
                        </Suspense>
                      }
                    />
                    <Route
                      path="puyer"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <PuyerApotek />
                        </Suspense>
                      }
                    />
                    <Route
                      path="drugs-condition"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsApotekDrugsCondition />
                        </Suspense>
                      }
                    />
                  </Route>
                )}
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.settings?.general,
                users.profile?.authority?.settings?.logo,
                users.profile?.authority?.settings?.bpjs,
                users.profile?.authority?.settings?.["instalasi-farmasi"],
                users.profile?.authority?.settings?.["coding-rm"],
                users.profile?.authority?.settings?.["point-of-sales"],
                users.profile?.authority?.settings?.["calendars"],
                users.profile?.authority?.settings?.["kiosk"],
                // users.profile?.authority?.settings?.["rawat-jalan"],
                users.profile?.authority?.settings?.["rawat-inap"],
                users.profile?.authority?.settings?.["hak-kewajiban-pasien"],
                users.profile?.authority?.settings?.["kiosk"],
                users.profile?.authority?.settings?.["announcers"],
                // users.profile?.authority?.settings?.notifications,
              ].some((item) => item)) && (
              <Route path="settings">
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.general) && (
                  <Route
                    path="general"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsGeneral />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.logo) && (
                  <Route
                    path="logo"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsLogo />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.[
                    "instalasi-farmasi"
                  ]) && (
                  <Route
                    path="instalasi-farmasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsInstalasiFarmasi />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["coding-rm"]) && (
                  <Route
                    path="coding-rm"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsCodingRM />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["point-of-sales"]) && (
                  <Route
                    path="point-of-sales"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsPointOfSales />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.bpjs) && (
                  <Route
                    path="bpjs"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsBPJS />
                      </Suspense>
                    }
                  />
                )}
                {/* {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["rawat-jalan"]) && (
                  <Route path="rawat-jalan" element={<SettingsRawatJalan />} />
                )} */}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["rawat-inap"]) && (
                  <Route
                    path="rawat-inap"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsRawatInap />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.[
                    "hak-kewajiban-pasien"
                  ]) && (
                  <Route
                    path="hak-kewajiban-pasien"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsHakKewajibanPasien />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["ugd"]) && (
                  <Route
                    path="ugd"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsUGD />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["oncall"]) && (
                  <Route
                    path="oncall"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsOnCall />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["calendars"]) && (
                  <Route
                    path="calendars"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsCalendars />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["kiosk"]) && (
                  <Route
                    path="kiosk"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsKiosk />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["announcers"]) && (
                  <Route
                    path="announcers"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsAnnouncers />
                      </Suspense>
                    }
                  />
                )}

                {/* {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["notifications"]) && (
                  <Route
                    path="notifications"
                    element={<SettingsNotifications />}
                  />
                )} */}
              </Route>
            )}

            {/* {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.integrations?.bpjs,
                users.profile?.authority?.integrations?.whatsapp,
              ].some((item) => item)) && ( */}
            <Route path="integrations">
              <Route path="bpjs">
                <Route path="pcare">
                  <Route
                    path="settings"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <IntegrationsBPJSSettings />
                      </Suspense>
                    }
                  />
                  <Route
                    path="encounter"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <IntegrationsBPJSEncounter />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>

              <Route path="crm">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Crm />
                    </Suspense>
                  }
                />
                <Route
                  path="overview"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Crm />
                    </Suspense>
                  }
                />
                <Route
                  path="marketing-campaign"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <MarketingCampaign />
                    </Suspense>
                  }
                />
                <Route
                  path="logs"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmRiwayat />
                    </Suspense>
                  }
                />
                <Route path="communication">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <CommunicationTemplateLists />
                      </Suspense>
                    }
                  />
                  <Route
                    path="create"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <CommunicationTemplateCreateUpdate />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <CommunicationTemplateCreateUpdate />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="feedback"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmFeedback />
                    </Suspense>
                  }
                />
                <Route
                  path="survey"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmSurvey />
                    </Suspense>
                  }
                />
                <Route
                  path="chatbot"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmChatbot />
                    </Suspense>
                  }
                />
                <Route
                  path="loyalty"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmLoyalty />
                    </Suspense>
                  }
                />
                <Route
                  path="omnichannel"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmOmnichannel />
                    </Suspense>
                  }
                />
                <Route
                  path="segmentation"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmSegmentation />
                    </Suspense>
                  }
                />
              </Route>

              <Route path="landing-page">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <IntegrationsLandingPage />
                    </Suspense>
                  }
                />
                <Route
                  path="welcome"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <IntegrationsLandingPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="satusehat">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <IntegrationsSatuSehat />
                    </Suspense>
                  }
                />
                <Route
                  path="welcome"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <IntegrationsSatuSehatWelcome />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* )} */}

            {(users.profile?.entity === "owner" ||
              [users.profile?.authority?.histories].some((item) => item)) && (
              <Route path="histories">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Histories />
                    </Suspense>
                  }
                />
              </Route>
            )}
          </Route>

          <Route path="*" element={<AuthorizedOnly />} />
        </Route>

        <Route path="login" element={<Login />} />

        {/* <Route
          path="help"
          element={
            <Suspense fallback={<LoadingOutlet />}>
              <Help />
            </Suspense>
          }
        /> */}

        <Route path="blogs">
          <Route
            index
            element={
              <Suspense fallback={<LoadingOutlet />}>
                <Blogs />
              </Suspense>
            }
          />
          <Route
            path=":slug"
            element={
              <Suspense fallback={<LoadingOutlet />}>
                <BlogsSlug />
              </Suspense>
            }
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>

      {locationState?.backgroundLocation && (
        <Routes>
          <Route path="clinics">
            <Route path=":id">
              <Route path="manages">
                <Route path="patients">
                  <Route path=":idPatient">
                    <Route
                      path="medical-records"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPasienModalMedicalRecords />
                        </Suspense>
                      }
                    />
                    <Route
                      path="general-consent"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPasienModalGeneralConsent />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      )}
    </div>
  );
}
